const theme = [
  {
    name: "base-bg-color",
    get: (colors) => {
      const colorWithId50 = colors?.find((color) => color.objectId === 50);
      return colorWithId50?.bgStartColor;
    }
  },
  {
    name: "primary-text-color",
    get: (colors) => {
      const colorWithId51 = colors?.find((color) => color.objectId === 51);
      return colorWithId51?.startColor;
    }
  },
  {
    name: "secondary-text-color",
    get: (colors) => {
      const colorWithId52 = colors?.find((color) => color.objectId === 52);
      return colorWithId52?.startColor;
    }
  },
  {
    name: "button-bg-color",
    get: (colors) => {
      const colorWithId53 = colors?.find((color) => color.objectId === 53);
      return colorWithId53?.bgStartColor;
    }
  },
  {
    name: "button-text-color",
    get: (colors) => {
      const colorWithId53 = colors?.find((color) => color.objectId === 53);
      return colorWithId53?.startColor;
    }
  },
  {
    name: "secondary-button-bg-color",
    get: (colors) => {
      const colorWithId53 = colors?.find((color) => color.objectId === 53);
      return colorWithId53?.bgEndColor;
    }
  },
  {
    name: "secondary-button-text-color",
    get: (colors) => {
      const colorWithId53 = colors?.find((color) => color.objectId === 53);
      return colorWithId53?.endColor;
    }
  },

  {
    name: "text-field-bg-color",
    get: (colors) => {
      const colorWithId54 = colors?.find((color) => color.objectId === 54);
      return colorWithId54?.bgStartColor;
    }
  },
  {
    name: "text-field-text-color",
    get: (colors) => {
      const colorWithId54 = colors?.find((color) => color.objectId === 54);
      return colorWithId54?.startColor;
    }
  },
  {
    name: "card-bg-color",
    get: (colors) => {
      const colorWithId55 = colors?.find((color) => color.objectId === 55);
      return colorWithId55?.bgStartColor;
    }
  },
  {
    name: "third-text-color",
    get: (colors) => {
      const colorWithId56 = colors?.find((color) => color.objectId === 56);
      return colorWithId56?.startColor;
    }
  },
  {
    name: "description-text-color",
    get: (colors) => {
      const colorWithId57 = colors?.find((color) => color.objectId === 57);
      return colorWithId57?.startColor;
    }
  },
  {
    name: "tag-text-color",
    get: (colors) => {
      const colorWithId58 = colors?.find((color) => color.objectId === 58);
      return colorWithId58?.startColor;
    }
  },
  {
    name: "tag-text-bg-color",
    get: (colors) => {
      const colorWithId58 = colors?.find((color) => color.objectId === 58);
      return colorWithId58?.bgStartColor;
    }
  },
  {
    name: "text-over-dark-bg",
    get: (colors) => {
      const colorWithId59 = colors?.find((color) => color.objectId === 59);
      return colorWithId59?.startColor;
    }
  },
  {
    name: "text-over-light-bg",
    get: (colors) => {
      const colorWithId59 = colors?.find((color) => color.objectId === 59);
      return colorWithId59?.endColor;
    }
  }
];

const imgsSrc = [
  {
    name: "logo",
    get: (imgs) => {
      const imgSrc = imgs?.find((img) => img.imageId === 136);
      return imgSrc?.imageUrl;
    }
  },
  {
    name: "hero-bg",
    get: (imgs) => {
      const imgSrc = imgs?.find((img) => img.imageId === 129);
      return imgSrc?.imageUrl;
    }
  },
  {
    name: "how-it-works-img",
    get: (imgs) => {
      const imgSrc = imgs?.find((img) => img.imageId === 132);
      return imgSrc?.imageUrl;
    }
  },
  {
    name: "login-page-bg-img",
    get: (imgs) => {
      const imgSrc = imgs?.find((img) => img.imageId === 133);
      return imgSrc?.imageUrl;
    }
  }
];

const options = [
  {
    name: "allow_login",
    get: (options) => {
      const option = options?.find((item) => item.id === 1);
      return option?.allow_login;
    }
  },
  {
    name: "allow_signup",
    get: (options) => {
      const option = options?.find((item) => item.id === 2);
      return option?.allow_signup;
    }
  },
  {
    name: "allow_new_page",
    get: (options) => {
      const option = options?.find((item) => item.id === 3);
      return option?.allow_new_page;
    }
  }
];

const pageAppearance = [
  {
    name: "allow_compounds_sec",
    get: (Pages) => {
      const obj = Pages?.find((item) => item.id === 2);
      return obj?.allow_compounds_sec;
    }
  },
  {
    name: "allow_best_deals_sec",
    get: (pages) => {
      const obj = pages?.find((item) => item.id === 3);
      return obj?.allow_best_deals_sec;
    }
  }
];

const labels = [
  {
    name: "hero_title_en",
    get: (labels) => {
      const obj = labels?.find((item) => item.id === 680);
      return obj?.label;
    }
  },
  {
    name: "hero_title_ar",
    get: (labels) => {
      const obj = labels?.find((item) => item.id === 681);
      return obj?.label;
    }
  }
];

const defaultColors = {
  "base-bg-color": "#fafafa",
  "primary-text-color": "#8c959f",
  "secondary-text-color": "#1a1a1a",
  "button-bg-color": "#0F7763",
  "button-text-color": "#fff",
  "secondary-button-bg-color": "#d5b02d",
  "secondary-button-text-color": "#fff",
  "text-field-bg-color": "#00244d",
  "text-field-text-color": "#fff",
  "third-text-color": "#fff",
  "description-text-color": "#333",
  "tag-text-color": "#000",
  "tag-text-bg-color": "#eee",
  "text-over-dark-bg": "#fff",
  "text-over-light-bg": "#333"
};

const defaultImgsSrc = {
  logo: "",
  "hero-bg": "",
  "how-it-works-img": "",
  "login-page-bg-img": ""
};

const defaultOptions = {
  allow_login: false,
  allow_signup: false,
  allow_new_page: false
};

const defaultPageAppearance = {
  allow_compounds_sec: false,
  allow_best_deals_sec: false
};

const defaultLabels = {
  hero_title_en: "",
  hero_title_ar: ""
};

const getInitialData = (config, defaultData, dataItems, configKey) => {
  const data = { ...defaultData };
  const configData = config?.params?.[configKey];
  dataItems.forEach((item) => {
    const value = item.get(configData);
    if (value) {
      data[item.name] = value;
    }
  });
  return data;
};

const getInitialWebvisibility = (config, defaultData, dataItems, configKey) => {
  const data = { ...defaultData };
  const configData = config?.params?.webVisibility[configKey];
  dataItems.forEach((item) => {
    const value = item.get(configData);
    if (value) {
      data[item.name] = value;
    }
  });
  return data;
};

export const getInitialColors = (config) =>
  getInitialData(config, defaultColors, theme, "Colors");
export const getInitialImgSrc = (config) =>
  getInitialData(config, defaultImgsSrc, imgsSrc, "Images");
export const getInitialOptions = (config) =>
  getInitialWebvisibility(config, defaultOptions, options, "options");

export const getInitialPageAppearance = (config) =>
  getInitialWebvisibility(
    config,
    defaultPageAppearance,
    pageAppearance,
    "page_appearance"
  );

export const getInitialLabels = (config) =>
  getInitialData(config, defaultLabels, labels, "Labels");
