import React, { createContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { navigate } from "gatsby";

import {
  getInitialImgSrc,
  getInitialLabels,
  getInitialOptions,
  getInitialPageAppearance
} from "./configurations/helpers";
import { fetchConfigColors } from "./configurations/colors";
import { getSlug } from "./helperFunctions";

export const AuthContext = createContext();

const slugName = getSlug();

const AuthContextProvider = ({ children }) => {
  const [sessionToken, setSessionToken] = useState(null);
  const [objectId, setObjectId] = useState(null);
  const [slug, setSlug] = useState(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("sessionToken");
    if (token) {
      setSessionToken(token);
    }
  }, []);

  useEffect(() => {
    const objectId = localStorage.getItem("objectId");
    let clearLocalStorageTimer;

    if (objectId) {
      clearLocalStorageTimer = setTimeout(() => {
        localStorage.removeItem("sessionToken");
        localStorage.removeItem("objectId");
        navigate(`/${slugName}/login`);
      }, 15 * 60 * 1000);
    }

    return () => {
      if (clearLocalStorageTimer) {
        clearTimeout(clearLocalStorageTimer);
      }
    };
  }, []);

  const { data: configData, isLoading: isLogoLoading } = useQuery({
    queryKey: ["configData-key"],
    queryFn: fetchConfigColors,
    retry: 7,
    retryDelay: 2500,
    enabled: slug ? !!slug : !!slugName
  });

  const imgsSrcData = configData ? getInitialImgSrc(configData) : "";
  const configOptionsData = configData ? getInitialOptions(configData) : "";
  const configPageAppearance = configData
    ? getInitialPageAppearance(configData)
    : "";

  const labelsData = configData ? getInitialLabels(configData) : "";

  const setObject = (id) => {
    setObjectId(id);
    localStorage.setItem("objectId", id);
  };

  const setSlugName = (slug) => {
    setSlug(slug);
    localStorage.setItem("slug", slug);
  };

  const setToken = (token) => {
    setSessionToken(token);
    localStorage.setItem("sessionToken", token);
  };

  const clearToken = () => {
    setSessionToken(null);
    localStorage.removeItem("sessionToken");
    localStorage.removeItem("objectId");
    localStorage.removeItem("slug");
  };

  const isLoggedIn = !!sessionToken;

  const authContextValue = {
    sessionToken,
    setToken,
    clearToken,
    isAuthenticated: isLoggedIn,
    setObject,
    email,
    setEmail,
    setSlugName,
    imgsSrcData,
    configOptionsData,
    isLogoLoading,
    configPageAppearance,
    labelsData
  };
  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
