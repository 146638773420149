import { getSlug } from "../helperFunctions";
import { getInitialColors } from "./helpers";

export async function fetchConfigColors() {
  const slug = getSlug();
  try {
    const response = await fetch(
      `https://${slug}.herokuapp.com/idengager/config`,
      {
        headers: {
          "Idengager-Application-Id": `${slug}`
        }
      }
    );

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching colors: ", error);
    return [];
  }
}

export const setupColors = (colorsData) => {
  const initialColors = getInitialColors(colorsData);

  document.documentElement.style.setProperty(
    "--initial-base-bg-color",
    initialColors["base-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-primary-text-color",
    initialColors["primary-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-secondary-text-color",
    initialColors["secondary-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-button-bg-color",
    initialColors["button-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-button-text-color",
    initialColors["button-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-secondary-button-bg-color",
    initialColors["secondary-button-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-secondary-button-text-color",
    initialColors["secondary-button-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-text-field-bg-color",
    initialColors["text-field-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-text-field-text-color",
    initialColors["text-field-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-card-bg-color",
    initialColors["card-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-third-text-color",
    initialColors["third-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-description-text-color",
    initialColors["description-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-tag-text-color",
    initialColors["tag-text-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-tag-text-bg-color",
    initialColors["tag-text-bg-color"]
  );
  document.documentElement.style.setProperty(
    "--initial-text-over-dark-bg",
    initialColors["text-over-dark-bg"]
  );
  document.documentElement.style.setProperty(
    "--initial-text-over-light-bg",
    initialColors["text-over-light-bg"]
  );
  return initialColors;
};
