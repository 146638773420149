import React, { Fragment, useEffect } from "react";
import { Provider } from "react-redux";
import { legacy_createStore as createStore } from "redux";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

import reducer from "./src/redux/reducer";
import AuthContextProvider from "./src/utils/auth-context";
import {
  fetchConfigColors,
  setupColors
} from "./src/utils/configurations/colors";
import { getSlug } from "./src/utils/helperFunctions";

function SetupColors() {
  const slug = getSlug();
  const { data: configData, isLoading: isLogoLoading } = useQuery({
    queryKey: ["configData-key"],
    queryFn: fetchConfigColors,
    retry: 7,
    retryDelay: 2500,
    enabled: !!slug
  });
  useEffect(() => {
    configData && setupColors(configData);
  }, [configData]);

  return null;
}

// eslint-disable-next-line react/display-name,react/prop-types
const wrapWithProvider = ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore(reducer);
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <SetupColors />
        <AuthContextProvider>{element}</AuthContextProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default wrapWithProvider;
