import actionType from "./actionTypes";
const INITIALSTATE = {
  allUnits: [],
  brandFilterd: [],
  filterdUnits: [],
  typeOptions: [],
  singleUnit: {},
  minPrice: "",
  selectedCatolgId: "",
  activityCategory: "apartment",
  profileCategory: "info",
  resetPassword: false,
  userData: null,
  showAlertDetails: false,
  selectedValues: {
    minPrice: 1000,
    maxPrice: 0,
    minArea: 230,
    maxArea: 0,
  },
};
const reducer = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case actionType.GET_DATA:
      return {
        ...state,
        allUnits: action.payload.data,
        filterdUnits: action.payload.data,
      };
      break;
    case actionType.BRANDFILTER:
      return {
        ...state,
        filterdUnits: state.allUnits.filter(
          (unit) => unit.Make_c === action.payload.brand
        ),
        brandFilterd: state.allUnits.filter(
          (unit) => unit.Make_c === action.payload.brand
        ),
        typeOptions: state.allUnits
          .filter((unit) => unit.Make_c === action.payload.brand)
          .map((units) => units.Options_c)
          .filter((item, i, arr) => arr.indexOf(item) === i),
      };
      break;
    case actionType.TYPEFILTER:
      return {
        ...state,
        filterdUnits: state.allUnits
          .filter((element) => {
            return state.brandFilterd.includes(element);
          })
          .filter((elm) => elm.Options_c === action.payload.type),
      };
      break;
    case actionType.SINGLE_UNIT:
      return {
        ...state,
        singleUnit: state.allUnits?.find(
          (item) => item.objectId === action.payload.id
        ),
      };
      break;
    case actionType.UNIT_MINPRICE:
      return { ...state, minPrice: action.payload.price };
      break;
    case actionType.ACTIVITY_SELECTION:
      return { ...state, activityCategory: action.payload.type };
      break;
    case actionType.PROFILE_SELECTION:
      return { ...state, profileCategory: action.payload.type };
      break;
    case actionType.RESET_PASSWORD:
      return { ...state, resetPassword: true };
    case actionType.SELECTED_CATLOG_ID:
      return { ...state, selectedCatolgId: action.payload.id };
      break;
    case actionType.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case actionType.TOGGLE_ALERT_DETAILS:
      return { ...state, showAlertDetails: !state.showAlertDetails };
    case actionType.SET_SELECTED_VALUES:
      return { ...state, selectedValues: action.payload.selectedValues };
    default:
      return state;
      break;
  }
};

export default reducer;
