import React from "react";

import { Link } from "gatsby";

export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getOptionsArray = (data, valueName) => {
  const developerOptionsSet = new Set();
  const developerOptionsArray = [];

  data?.forEach((property, index) => {
    if (
      property[valueName] !== undefined &&
      !developerOptionsSet.has(property[valueName])
    ) {
      developerOptionsSet.add(property[valueName]);
      developerOptionsArray.push({
        value: property[valueName],
        label: property[valueName],
        key: `${property[valueName]}_${index}`
      });
    }
  });

  return developerOptionsArray;
};

export function formatDate(dateString) {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  return formattedDate;
}

export function formatTime(dateString) {
  const date = new Date(dateString);
  const formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true
  });
  return formattedTime;
}

export function formatString(str) {
  let withHyphens = str.replace(/\s+/g, "-");

  let result = withHyphens.charAt(0).toLowerCase() + withHyphens.slice(1);

  return result;
}

export function revertString(str) {
  let withSpaces = str.replace(/-/g, " ");

  let result = withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);

  return result;
}

function extractSlug(url) {
  const urlObj = new URL(url);

  const pathname = urlObj.pathname;

  const parts = pathname.split("/");

  const slug = parts[1];

  return slug;
}

export const yearString = (dateString) => {
  let dateParts = dateString.split("-");
  return parseInt(dateParts[0]);
};

export const getSlug = () => {
  if (typeof window !== "undefined") {
    const slugName = localStorage.getItem("slug");
    if (slugName) {
      return slugName;
    } else {
      const url = window.location.href;
      const extractedSlug = extractSlug(url);
      return extractedSlug;
    }
  }
};

export function createBreadcrumbItems(items) {
  const breadcrumbItems = [
    {
      title: (
        <Link to="/" className="text-base font-bold capitalize">
          home
        </Link>
      )
    }
  ];

  items.forEach((item) => {
    breadcrumbItems.push({
      title: (
        <Link to={item.path} className="capitalize text-base font-bold">
          {item.label}
        </Link>
      )
    });
  });

  return breadcrumbItems;
}

export const imageOnError = (e, img) => {
  e.target.onerror = null;
  e.target.src = img;
  e.target.clasName = "errorImg";
};
