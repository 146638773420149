const actionType = {
  SELECTED_CATLOG_ID: "SELECTED_CATLOG_ID",
  GET_DATA: "GET_DATA",
  SINGLE_UNIT: "SINGLE_UNIT",
  BRANDFILTER: "BRANDFILTER",
  TYPEFILTER: "TYPEFILTER",
  UNIT_MINPRICE: "UNIT_MINPRICE",
  ACTIVITY_SELECTION: "ACTIVITY_SELECTION",
  PROFILE_SELECTION: "PROFILE_SELECTION",
  RESET_PASSWORD: "RESET_PASSWORD",
  LOGIN: "LOGIN",
  TOGGLE_ALERT_DETAILS: "TOGGLE_ALERT_DETAILS",
  SET_SELECTED_VALUES: "SET_SELECTED_VALUES",
  SET_USER_DATA: "SET_USER_DATA",
};
export default actionType;
